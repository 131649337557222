import { createUniqueId } from "solid-js";
import { ErrorBoundary, For, Suspense } from "solid-js";
import { createScriptLoader } from "@solid-primitives/script-loader";
import Card from "~/components/Cards/Card";

import "~/components/shared/glide.core.min.css";
import "~/components/shared/GlideSlider.css";

import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import type { ItemReference } from "~/types/drupal_jsonapi";

export default function ProgramsNearbyList(props: {
  nearbyPrograms: ItemReference[];
}) {
  const domID = createUniqueId();
  createScriptLoader({
    src: "/libs/glide.min.js",
    async: true,
    onLoad() {
      setTimeout(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Glide(`#slider-${domID}`, {
          gap: 0,
          rewind: false,
          perView: 3,
          perSwipe: "|",
          breakpoints: {
            767: {
              gap: 48,
              perView: 1,
            },
            1217: {
              gap: 0,
              perView: 2,
            },
          },
        }).mount();
      }, 2000);
    },
  });

  return (
    <>
      <div class="content-part">
        <div id={`slider-${domID}`} class="glide glide-slider-programs">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides" data-test="programs">
              <For each={props.nearbyPrograms}>
                {(item) => (
                  <li class="glide__slide">
                    <ErrorBoundary
                      fallback={(err) => <div>Error: {err.message}</div>}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Card nid={item.id} item={item} />
                      </Suspense>
                    </ErrorBoundary>
                  </li>
                )}
              </For>
            </ul>
          </div>

          <div data-glide-el="controls">
            <button
              class="btn btn-icon glide__arrow glide__arrow--prev"
              data-glide-dir="|<"
              data-test="prev"
              aria-label="Précédent"
            >
              <IconKeyboardArrowLeft />
            </button>

            <button
              class="btn btn-icon glide__arrow glide__arrow--next"
              data-glide-dir="|>"
              data-test="next"
              aria-label="Suivant"
            >
              <IconKeyboardArrowRight />
            </button>
          </div>

          <div
            class={`glide__bullets bullets-count-${props.nearbyPrograms.length}`}
            data-glide-el="controls[nav]"
          >
            <For each={props.nearbyPrograms}>
              {(_, index) => (
                <button
                  class="glide__bullet"
                  data-glide-dir={`=${index()}`}
                  aria-label={`Slide ${index()}`}
                >
                  ●
                </button>
              )}
            </For>
          </div>
        </div>
      </div>
    </>
  );
}
